import React, { Component } from 'react'
import Piechart from './Piechart'
import Linechart from './Linechart'
import Paretochart from './Paretochart'
import StackedBarchart from './StackedBarchart'
import MultisetBarchart from './MultisetBarchart'

export default class Dashboard extends Component {
    constructor(props) {
        super(props)
        const getCumulativePer = (SalesData) => {
            let sortedSales = SalesData.sort(function (a, b) { return b - a })
            let cumulativeArr = []
            let cumulativePercentArr = []
            let total = sortedSales.reduce((accumulator, a) => {
                cumulativeArr.push(accumulator + a)
                return accumulator + a;
            }, 0)

            console.log(cumulativeArr)
            console.log(total)

            for (let i = 0; i < cumulativeArr.length; i++) {
                let per = Math.ceil((cumulativeArr[i] / total) * 100)
                cumulativePercentArr.push(per)
            }
            console.log(cumulativePercentArr)
            return cumulativePercentArr
            // this.state.series[1].data = cumulativePercentArr
            // this.setState({ isRenderAgain: true })
            // console.log(this.state)
        }
        this.state = {
            piechart: {
                series: [35, 62, 20, 90, 160],
                options: {
                    chart: {
                        // width: 380,
                        type: 'pie',
                        toolbar: {
                            show: true,
                            export: {
                                csv: {
                                    headerCategory: 'States',
                                }
                            }
                        },
                    },
                    title: {
                        text: 'Sales by state (Pie Chart)',
                        align: 'left'
                    },
                    labels: ['Delhi', 'Maharashtra', 'West Bengol', 'Haryana', 'Gujrat'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "₹ " + val + " Lakhs"
                            }
                        }
                    }
                },
            },
            linechart: {
                series: [{
                    name: "Sales in Lakhs",
                    data: ['10', "41", "35", "51", "49", "62", '69', "91", "148", "20", "90", "160"]
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Sales by Month (Line Chart)',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        title: {
                            text: 'Month'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Sales in lakhs'
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "₹ " + val + " Lakhs"
                            }
                        }
                    }
                },
            },
            multisetBarChart: {
                series: [{
                    name: '2018-2019',
                    data: [44, 20, 57, 80, 61, 58, 30, 70, 86]
                }, {
                    name: '2019-2020',
                    data: [76, 50, 16, 70, 38, 10, 91, 24, 90]
                }, {
                    name: '2020-2018',
                    data: [50, 70, 22, 90, 88, 20, 60, 53, 100]
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: true,
                            export: {
                                csv: {
                                    headerCategory: 'Customers',
                                    // headerValue: 'value',
                                }
                            }
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'Others'],
                        title: {
                            text: 'Customers'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Sales in Lakhs'
                        }
                    },
                    title: {
                        text: 'Sales by Customers (Yearwise Comparison)',
                        align: 'left'
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "₹ " + val + " Lakhs"
                            }
                        }
                    }
                },
            },
            stackedBarChart: {
                series: [{
                    name: '2018-2019',
                    data: [44, 20, 57, 80, 61, 58, 30, 70, 86]
                }, {
                    name: '2019-2020',
                    data: [76, 50, 16, 70, 38, 10, 91, 24, 90]
                }, {
                    name: '2020-2021',
                    data: [50, 70, 22, 90, 88, 20, 60, 53, 100]
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true,
                            export: {
                                csv: {
                                    headerCategory: 'Customers',
                                    // headerValue: 'value',
                                }
                            }
                        },
                        zoom: {
                            enabled: true
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10
                        },
                    },
                    xaxis: {
                        categories: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'Others'],
                        title: {
                            text: 'Customers'
                        }
                    },
                    yaxis: {
                        title: {
                            text: "Sales in Lakhs"
                        }
                    },
                    title: {
                        text: 'Sales by Customers (Customerwise Comparison)',
                        align: 'left'
                    },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "₹ " + val + " Lakhs"
                            }
                        }
                    }
                },
            },
            paretoChart: {
                series: [{
                    name: 'Sales in Lakhs',
                    type: 'column',
                    data: [44, 20, 57, 80, 61, 58, 30, 70, 86].sort(function (a, b) { return b - a })
                }, {
                    name: 'cumulative-percent',
                    type: 'line',
                    data: getCumulativePer([44, 20, 57, 80, 61, 58, 30, 70, 86])
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: true,
                            export: {
                                csv: {
                                    headerCategory: 'Customers',
                                    // headerValue: 'value',
                                }
                            }
                        },
                    },
                    stroke: {
                        width: [0, 4]
                    },
                    title: {
                        text: 'Sales by Customers pareto analysis'
                    },
                    dataLabels: {
                        enabled: true,
                        enabledOnSeries: [1]
                    },
                    // labels: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'Others'],
                    xaxis: {
                        // type: 'datetime'
                        categories: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'Others'],
                        title: {
                            text: 'Customers'
                        }
                    },
                    yaxis: [{
                        // categories: ['10', '20', '30', '40', '50', '60', '70', '80', '90','100'],
                        title: {
                            text: 'Sales in Lakhs',
                        },

                    }, {
                        opposite: true,
                        title: {
                            text: 'Cumulative percent of sales'
                        }
                    }],
                    // tooltip: {
                    //     y: {
                    //         opposite:false,
                    //         formatter: function (val,index,b,c) {
                    //             console.log(val,index,b,c)
                    //             return "₹ " + val + " Lakhs"
                    //         }
                    //     }
                    // }
                },
            }
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Linechart
                            series={this.state.linechart.series}
                            options={this.state.linechart.options}
                        />
                    </div>
                    <div className="col-md-6" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Piechart
                            series={this.state.piechart.series}
                            options={this.state.piechart.options}
                        />
                    </div>
                    <div className="col-md-6" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <MultisetBarchart
                            series={this.state.multisetBarChart.series}
                            options={this.state.multisetBarChart.options}
                        />
                    </div>
                    <div className="col-md-6" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <StackedBarchart
                            series={this.state.stackedBarChart.series}
                            options={this.state.stackedBarChart.options}
                        />
                    </div>
                    <div className="col-md-6" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Paretochart
                            series={this.state.paretoChart.series}
                            options={this.state.paretoChart.options}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
