import React, { Component } from 'react'
// import InputBox from '../usefulcomps/InputBox'
import './Thousand.css'
var dateStr = ''
export default class Thousand extends Component {
    constructor(props){
        super(props)
        const getStartDate = (props) => {
            if(this.props.defdata != undefined){
                if (this.props.defdata.indexOf(',') > -1) { 
                    var arr = this.props.defdata.split(',')
                    var startDate = arr[0].split(' ')[0]
                    return startDate
                }
            }
        }
        const getEndDate = (props) => {
            if(this.props.defdata != undefined){
                if (this.props.defdata.indexOf(',') > -1) { 
                    var arr = this.props.defdata.split(',')
                    var endDate = arr[1].split(' ')[0]
                    return endDate
                }
            }
        }
        this.state={
            startDate:getStartDate(),endDate:getEndDate()
        }
        this.storeStartDate = this.storeStartDate.bind(this)
        this.storeEndDate = this.storeEndDate.bind(this)
    }
    componentDidMount(){
        console.log(this.state)
    }
    storeStartDate(e){
        if(e.target.value != ""){
            var startDate = e.target.value+' 00:00:00.000'
            var endDate = this.state.endDate+' 00:00:00.000'
            var str = startDate+','+endDate
            if(new Date(endDate) >= new Date(startDate)){
                this.props.onChange(str)
                this.setState({startDate:e.target.value})
            }else{
                alert("End Date must be greater than Start Date")
            }
        }
        console.log(e.target.value)
    }
    storeEndDate(e){
        if(e.target.value != ""){
            var startDate = this.state.startDate+' 00:00:00.000'
            var endDate = e.target.value+' 00:00:00.000'
            var str = startDate+','+endDate
            if(new Date(endDate) >= new Date(startDate)){
                this.props.onChange(str)
                this.setState({endDate:e.target.value})
            }else{
                alert("End Date must be greater than Start Date")
            }
        }
        console.log(e.target.value)
    }
    render() {
        const style={
            border:"none",
            background:'none',
            paddingLeft:'0px',
            marginBottom:'15px'
          }
        return (
            <>
            <div className='form-group' style={{paddingBottom:"10px"}}>
                <label htmlFor='startDate' style={{fontWeight:'600',fontSize:'18px'}}>Start Date</label>
                <input style={style} type="date" id='startDate' value={this.state.startDate} className='form-control' onChange={(e) => this.storeStartDate(e)}/>
            </div>
            <div className='form-group' style={{paddingBottom:"10px"}}>
                <label htmlFor='endDate' style={{fontWeight:'600',fontSize:'18px'}}>End Date</label>
                <input style={style} type="date" id='endDate' value={this.state.endDate} className='form-control' onChange={(e) => this.storeEndDate(e)}/>
            </div>
            </>
        )
    }
}
