import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

export default class CRMLinechart extends Component {
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.props.options} series={this.props.series} type="line"
                />
            </div>
        )
    }
}
