import React, { Component } from "react";
import Hexapi from './HexAPI/Hexapi'
import Aes from './Aes/Aes'
import Table from "react-bootstrap/Table";
import "./table.css";
import box from "./box.png"
import { ModalBody, Spinner } from "react-bootstrap"
import { Modal } from "react-bootstrap"
import DoDisturbOffSharpIcon from '@mui/icons-material/DoDisturbOffSharp';
import AddTaskSharpIcon from '@mui/icons-material/AddTaskSharp';
import CallIcon from '@mui/icons-material/Call';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {Input} from './HexInput/InputBox'
import { DatePicker, Datetimepicker } from './Calendar/Calender'  
import Button from './HexButton/button'
import {TimeFormatterV2} from "./TimeFormatter";
import { SsidChart } from "@mui/icons-material";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert"; // Import
import Swal from "sweetalert2";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "./show.css"
// sessionStorage.setItem('Apipathurl', 'https://crm.trackhrapp.com/')

class Show extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalshow:false,
            updateshow:false,
            enquiryshow:false,
            editshow:false,
            openDatePicker:false,
            updatedate:"",
            nextstep:"",
            remark:"",
            // closing enquiry
            EnquiryConclusion:"",
            ClosingRemarks:"",

            updatekeys:[],
            updateheaderrow:[],
            labelupdate:[],
            labelinputupdate:[],
            stageprogress:[],
          
            Stageconclusion:[],
            // conclusionvalue:"",
            recid:"",
            stageid:"",
            closestatus:"-1",
      isModalQueryHitComplete: true,
      isapihitcomplete:false,
      isdatafound:false,
          call:"",
          customerid:"",

          // edit form modal

          Marketedby:"",
            appusercount:"",
            machineusercount:"",
            editremark:"",
            attendancemachine:"None",
            attendancemachinequality:"",
            forregisteruseronly:"",
            entercustomerid:"",
            companyname:"",
            companysize:"",
            contactperson:"",
            contactnumber:"",
            email:"",
            designation:"",
            officeaddress:"",
            leadsourceid:"",
            LeadSource:[],
            AgentList:[],
            IncludeCards:false

        }


        // edit form modal

        this.storeremark = this.storeremark.bind(this);
        this.storemachineusercount = this.storemachineusercount.bind(this);
        this.attendancemachine = this.attendancemachine.bind(this);
        this.attendancemachinequality = this.attendancemachinequality.bind(this);
        this.companyname = this.companyname.bind(this);
        this.companysize = this.companysize.bind(this);
        this.contactperson = this.contactperson.bind(this);
        this.contactnumber = this.contactnumber.bind(this);
        this.email = this.email.bind(this);
        this.designation = this.designation.bind(this);
        this.officeaddress = this.officeaddress.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);


    }

  callfunction=React.createRef()



    Leadinfogetlist(recid){
    this.setState({ isapihitcomplete: false, isdatafound: false })
  
      let obj = {
        'query': `[dbo].[CRM_Proc_LeadDetailsInfo_GetList]@EnquiryID='{0}'`,
        'queryArr': [
          `${recid}`,
         
        ]
      }
      Hexapi(obj).then(resp => {
        console.log(resp[''])
        if (resp[''].length > 0 && resp[''] != null) {
          var keys = Object.keys(resp[''][0]);
          console.log(keys)
          this.setState({ updatekeys: keys, updateheaderrow: resp[''], isdatafound: true })
        }  else {
                this.setState({ isdatafound: false })
              }
      }).then(resp => {
            this.setState({ isapihitcomplete: true })
          })
    }

    LeadMasterinfo(recid){
      let obj = {
        'query': `[dbo].[CRM_Proc_LeadMasterInfo_GetDataV2]@EnquiryID='{0}'`,
        'queryArr': [
          `${recid}`,
         
        ]
      }
      Hexapi(obj).then(resp => {
        console.log(resp)
        console.log(resp[''])

        var data=resp['']
        let arr =[]
        for(let i= 0; i<data.length;i++){
          let labelkeys =  Object.keys(data[i])
          console.log(labelkeys)
          for(let j= 0; j<labelkeys.length;j++){
            let obj = {
              name:Object.keys(data[i])[j],
              value:data[i][Object.keys(data[i])[j]]
            }
            // console.log()
            // console.log(data[i][Object.keys(data[i])[j]])
arr.push(obj)
          }
        }
        console.log(arr)
        var labelkeys = Object.keys(resp[''][0]);

        this.setState({labelupdate:arr})
        // if (resp[''].length > 0 && resp[''] != null) {
        
        //   this.setState({ })
        // } 
      
      })
    }

   

    ActionModal(recid){
      this.setState({ isapihitcomplete: false, isdatafound: false })
  
      let obj = {
        'query': `	[dbo].[CRM_Proc_ActionModal_GetData]@EnquiryID='{0}'`,
        'queryArr': [`${recid}`]
      }
      Hexapi(obj).then(resp => {
        console.log(resp[''])
        if (resp[''].length > 0 && resp[''] != null) {
         
          var date =resp[''][0].lastfollowupdate.date
          var storeupdatedate =TimeFormatterV2(date,'fulldate','YYYY-MM-DD')
        // this.setState({stageid:s.stageid})
          this.setState({ stageid:resp[''][0].stageid,call:resp[''][0].contactnumber,isdatafound: true })
        }  else {
                this.setState({ isdatafound: false })
              }
      }).then(resp => {
            this.setState({ isapihitcomplete: true })
          })
    }

    rowModalOpen(s){
        this.setState({modalshow:true})
        console.log(s)
        this.setState({recid:s.enquiryid})
        this.setState({customerid:s["customer id"]})
        // this.setState({stageid:s.stageid})
        // this.setState({call:s["contact number"]})
       
        this.Leadinfogetlist(s.enquiryid)
        this.LeadMasterinfo(s.enquiryid)
        this.ActionModal(s.enquiryid)
   this.geteditfromdata(s.enquiryid)

   
 

    }
    updateModalOpen(){
        this.setState({updateshow:true})
      this.stageprogress()
        
    }
    enquiryModalOpen(){
        this.setState({enquiryshow:true})
        
          this.Stageconclusion()
          }

          editModalOpen(){
            this.setState({editshow:true})
            
              }

    handleClose = () => {
        this.setState({
          modalshow: false,
       
        });
      };

      updateClose = ()=>{
        this.setState({updateshow: false});  
      }
      enquiryClose= () =>{
        this.setState({enquiryshow: false});  

      }

      editClose=()=>{
        this.setState({editshow:false})
      }

      handleChange = ()=>{
        this.setState({openDatePicker:true})
      }

   storeDate = (date) => {
    this.setState({openDatePicker:false,updatedate:TimeFormatterV2(date,'fulldate','YYYY-MM-DD')})
 
  };
      
  handleupdatebutton(){
    console.log("hello update button")

    if (this.state.updatedate != undefined && this.state.updatedate !== "") {
    if (this.state.stageid != undefined && this.state.stageid !== "") {


    confirmAlert({
      message:
      "Are you sure you want to submit? ",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          this.setState({
            isModalQueryHitComplete: false,
          });
          let obj = {
            'query': `[dbo].[CRM_Proc_Updatefollowup_Submit]@EnquiryID='{0}',@FollowupDate='{1}',@Stage='{2}',@NextStep='{3}',@Remarks='{4}'
          `,
            'queryArr': [`${this.state.recid}`,`${this.state.updatedate}`,`${this.state.stageid}`,`${this.state.nextstep}`,`${this.state.remark}`]
          }
          Hexapi(obj).then(resp => {
            this.setState({
              isModalQueryHitComplete: true,
            });
            console.log(resp)
            console.log(resp[''])

            if (
              resp[""][0]
                .command == "0"
            ) {
              
            }else{
              Swal.fire({
                text: resp[""][0].msg,
              }).then((resp)=>{
                window.location.reload()
              })
            }
          
          })
  
        },
      },
      {
        label: "No",
      },
    ],
    overlayClassName:
      "confirm__overlayClass",
  });

}else{
  Swal.fire({
    text: "Enter Stage",
  })
}
    }else{
      Swal.fire({
        text: "Enter Date"
      })
    }

    
  }

  handleenquiryclosebutton(){
    console.log("hello enquiry close ")
    if (this.state.stageid != undefined && this.state.stageid !== "") {



    confirmAlert({
      message:
      "Are you sure you want to submit? ",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          this.setState({
            isModalQueryHitComplete: false,
          });
          let obj = {
            'query': `[dbo].[CRM_Proc_CloseEnquiry_Submit]@EnquiryID='{0}',@Remarks='{1}',@Status='{2}',@Conclusion='{3}'`,
            'queryArr': [`${this.state.recid}`,`${this.state.ClosingRemarks}`,`${this.state.closestatus}`,`${this.state.stageid}`]
          }
          Hexapi(obj).then(resp => {
            this.setState({
              isModalQueryHitComplete: true,
            });
            console.log(resp)
            console.log(resp[''])
            if (
              resp[""][0]
                .command == "0"
            ) {
              
            }else{
              Swal.fire({
                text: resp[""][0].msg,
              }).then((resp)=>{
                window.location.reload()
              })
            }
          
          })
        
  
        },
      },
      {
        label: "No",
      },
    ],
    overlayClassName:
      "confirm__overlayClass",
  });

}else{
  Swal.fire({
    text: "Enter Enquiry Conclusion"
  })
}

  
  }

  stageprogress(){

   

let obj = {
  'query': `[dbo].[CRM_Proc_Stage_Progress_GetList]`,
  'queryArr': []
}
Hexapi(obj).then(resp => {
  console.log(resp[''])
  if (resp[''].length > 0 && resp[''] != null) {
   
    this.setState({stageprogress:resp[''] })
  } 

})



  }

  Stageconclusion(){
    let obj = {
      'query': `[dbo].[CRM_Proc_Stage_Conclusion_GetList]`,
      'queryArr': []
    }
    Hexapi(obj).then(resp => {
      console.log(resp[''])
      if (resp[''].length > 0 && resp[''] != null) {
       
        this.setState({Stageconclusion:resp[''] })
      } 
    
    })
  }

  storeconclusionvalue(e){
    this.setState({stageid: e.target.value });

  }
  storestageprogress(e){
    console.log(e.target.value)
    this.setState({stageid: e.target.value });

  }

  callnumber(e){
    console.log(document.getElementById("actionModalContactNumber"))
    
	window.location.href = 'tel:' + `${this.state.call}`;
  }

// delete enquiry
deleteEnquiry(){

  confirmAlert({
    message:
    "Are you sure you want to submit? ",
  buttons: [
    {
      label: "Yes",
      onClick: () => {
        this.setState({
          isModalQueryHitComplete: false,
        });
  let obj = {
    'query': `[dbo].[CRM_Proc_Enquiry_Delete] @EnquiryID='{0}'`,
    'queryArr': [`${this.state.recid}`]
  }
  Hexapi(obj).then(resp => {
    this.setState({
      isModalQueryHitComplete: true,
    });
    console.log(resp)
    console.log(resp[''])
    if (
      resp[""][0]
        .command == "0"
    ) {
      
    }else{
      Swal.fire({
        text: resp[""][0].msg,
      }).then((resp)=>{
        window.location.reload()
      })
    }
  
  })
},
},
{
  label: "No",
},
],
overlayClassName:
"confirm__overlayClass",
});
}



// edit form modal 

      
Marketedby(e){
  console.log(e.target.value);
  this.setState({Marketedby:e.target.value})   
}
storeappusercount(e){
   console.log(e.target.value);
   if(e.target.value >= 0)
   {
      this.setState({appusercount:e.target.value})
   }
  
   }

storeremark(e){
  console.log(e.target.value);
  this.setState({editremark:e.target.value})
}
storemachineusercount(e){
   console.log(e.target.value);
   console.log(document.getElementById("machinecountid"))
   this.setState({machineusercount:document.getElementById("machinecountid").value})
 var MachineUserCount =document.getElementById("machinecountid").value;
 console.log(MachineUserCount)

if (MachineUserCount == 0){
  document.getElementById("attendance-info").style.display = "none";
  e.target.value = 0;
  this.setState({attendancemachine :"none", attendancemachinequality:"0", IncludeCards:false})
}
else{
  document.getElementById("attendance-info").style.display = "flex";
  e.target.value = 1;
}

if (MachineUserCount == 1){
  document.getElementById("RFIDShow").style.display = "none";
  
}
if(e.target.value >= 0)
   {
      this.setState({machineusercount:e.target.value})
   }
  
}  
attendancemachine(e){
   console.log(e.target.value);
   this.setState({attendancemachine:e.target.value})
   var AttendanceMachine = document.getElementById("Modal-AttendanceMachine").value;
   console.log(AttendanceMachine)
   if (AttendanceMachine == 'RFID'){
       document.getElementById("RFIDShow").style.display = "block";
       document.getElementById("Modal-IncludeCards").checked = true
       this.setState({IncludeCards:true})

   }else{
       document.getElementById("RFIDShow").style.display = "none";
       document.getElementById("Modal-IncludeCards").checked = false
       this.setState({IncludeCards:false})
   }
   
  
}
attendancemachinequality(e){
   console.log(e.target.value);
   if(e.target.value >= 0)
   {
      this.setState({attendancemachinequality:e.target.value})
   }
}

companyname(e){
   console.log(e.target.value);
   this.setState({companyname:e.target.value})
}
companysize(e){
   console.log(e.target.value);
   if(e.target.value >= 0)
   {
      this.setState({companysize:e.target.value})
   }
}
contactperson(e)
{
   console.log(e.target.value);
   this.setState({contactperson:e.target.value})
}
contactnumber(e){
   const re = /^[0-9\b]+$/;
   if (e.target.value === "" || re.test(e.target.value)) {
     this.setState({contactnumber: e.target.value });
   }
   console.log(e.target.value);
}
email(e){
 

   console.log(e.target.value);
   this.setState({email:e.target.value});
}
designation(e){
   console.log(e.target.value);
   this.setState({designation:e.target.value})
}
officeaddress(e){
   console.log(e.target.value);
   this.setState({officeaddress:e.target.value})
}
leadsourceid(e){
  console.log(e.target.value);
  this.setState({leadsourceid:e.target.value})
}

editformsubmit(event) {
  event.preventDefault();
  console.log(event);

  if (this.state.Marketedby != undefined && this.state.Marketedby !== ""){
      if (this.state.companyname != undefined && this.state.companyname !== ""){
          if (this.state.companysize!= undefined && this.state.companysize !== ""){
          if (this.state.contactperson!= undefined && this.state.contactperson !== ""){


              if (
                  this.state.contactnumber.length == 10 ||
                  !this.state.contactnumber.length > 0
                ) {
              if (this.state.contactnumber!= undefined && this.state.contactnumber !== ""){
                   if (this.state.leadsourceid != undefined && this.state.leadsourceid !== "") {
                       if (this.state.appusercount != undefined && this.state.appusercount!== ""){
                          if (this.state.machineusercount != undefined && this.state.machineusercount !== ""){
                            const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
                            
                                                                            if (
                                                                              !regEx.test(
                                                                                this.state.email
                                                                              ) &&
                                                                              this.state.email !== ""
                                                                            ) {
                                                                              this.setState({
                                                                                message: "Email Invalid",
                                                                              });
                                                                              {
                                                                                setTimeout(() => {
                                                                                  Swal.fire({
                                                                                    text: this.state
                                                                                      .message,
                                                                                  });
                                                                                }, 500);
                                                                                return;
                                                                              }
                                                                            } else {
                                                                            }
                            confirmAlert({
                              message:
                              "Are you sure you want to submit? ",
                            buttons: [
                              {
                                label: "Yes",
                                onClick: () => {
                                  this.setState({
                                    isModalQueryHitComplete: false,
                                  });
                            let obj ={
                              'query':`[dbo].[CRM_Proc_EditEnquiry_Submit]@EnquiryID='{0}',@CompanyName='{1}',@CustomerName='{2}',@ContactNumber='{3}' ,@officeAddress='{4}' ,@EmailID='{5}' ,@Designation='{6}' ,@CompanySize='{7}',@AgentField='{8}',@AppUserCount='{9}',@MachineUserCount='{10}',@AttendanceMachine='{11}',@AttendanceMachineQuantity='{12}',@IncludeCards='{13}',@LeadSourceID='{14}',@Remarks='{15}'`,
'queryArr':[ `${this.state.recid}`, `${this.state.companyname}`, `${this.state.contactperson}`, `${this.state.contactnumber}`,`${this.state.officeaddress}`,`${this.state.email}`,`${this.state.designation}`,`${this.state.companysize}` ,`${this.state.Marketedby}`,`${this.state.appusercount}`,`${this.state.machineusercount}`,`${this.state.attendancemachine}`,`${this.state.attendancemachinequality}`,`${this.state.IncludeCards}`,`${this.state.leadsourceid}`,`${this.state.remark}`]
                                 }
                                 Hexapi(obj).then(resp => {
                                  this.setState({
                                    isModalQueryHitComplete: true,
                                  });
                                  console.log(resp)
                                  console.log(resp[''])
                                  if (
                                    resp[""][0]
                                      .command == "0"
                                  ) {
                                    
                                  }else{
                                    Swal.fire({
                                      text: resp[""][0].msg,
                                    }).then((resp)=>{
                                     this.setState({editshow:false,modalshow:false})
                  this.props.callfunction(true)

                                    })
                                  }
                                
                                }).catch(err=>{console.error(err)})

                                },
                              },
                              {
                                label: "No",
                              },
                              ],
                              overlayClassName:
                              "confirm__overlayClass",
                              });

                          }else{Swal.fire({text:"machine user count not filled"})}
                      }else{Swal.fire({text:"app user count not filled."})}
                  }else{Swal.fire({text:"leadsource not selectd."})}
              }else{Swal.fire({text:"Contact number not filled."})} 
            }else{Swal.fire({text:"Contact Number must be 10 digits."})}        
          }else{Swal.fire({text:"Enter Contact Person"})}
        }else{Swal.fire({text:"Enter Company Size"})}
      }else{Swal.fire({text:"Enter Company Name"})}
  }else{Swal.fire("Marketed by not selected.")}
}       


geteditfromdata(recid){
  let obj ={
    'query':`[dbo].[CRM_Proc_EditEnquiry_FormGetData]@EnquiryID='{0}'`,
    'queryArr':[`${recid}`]
}
Hexapi(obj).then(resp=>{
    console.log(resp)
    console.log(resp[''][0])
    this.setState({companyname:resp[''][0].companyname,contactperson:resp[''][0].customername,contactnumber:resp[''][0].contactnumber
                    ,email:resp[''][0].emailid,designation:resp[''][0].designation,officeaddress:resp[''][0].officeaddress
                  ,leadsourceid:resp[''][0].leadsource,appusercount:resp[''][0].appusercount,machineusercount:resp[''][0].machineusercount
                ,editremark:resp[''][0].remark,IncludeCards:resp[''][0].includecards,attendancemachine:resp[''][0].attendancemachine
              ,attendancemachinequality:resp[''][0].attendancemachinequantity,Marketedby:resp[''][0].agentfield,companysize:resp[''][0].companysize})


 }).catch(err=>{console.error(err)})

  // if(this.state.machineusercount==1){
  //   console.log("machineusercount is 1")
  // document.getElementById("attendance-info").style.display="flex";

  // }else{
  //   console.log("machineusercount is 0")
  // document.getElementById("attendance-info").style.display="none";

  
  // }
 
}


componentDidMount(){



  let obj ={
      'query':`[dbo].[CRM_Proc_CustomerEnquiry_FormGetData]`,
      'queryArr':[]
  }
  Hexapi(obj).then(resp=>{
      console.log(resp)

      this.setState({LeadSource:resp.leadsource, AgentList:resp.agent})
   }).catch(err=>{console.error(err)})

   const queryString = window.location.search;
   console.log(queryString);
   const urlParams = new URLSearchParams(queryString);
   console.log(urlParams.getAll('ag'));
   var agentid=urlParams.getAll('ag').toString();
   console.log(agentid);
   this.setState({Marketedby:agentid})


}
// edit form modal end 

checkboxchange(){
  this.setState({
    IncludeCards:!this.state.IncludeCards
  })
}
    render() {

        return (
            <>

        {/* when we reload on hiting the api spinner with modal spin  */}
        <Modal
        
          centered
          size="sm"
          id="loadingModal"
          animation={true}
          show={!this.state.isModalQueryHitComplete}
          onHide={() => this.setState({ isModalQueryHitComplete: true })}
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          backdrop="static"
          keyboard={false}
        >
          {" "}
          {/* backdrop="static" keyboard={false} */}
          <Modal.Body>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                paddingBottom: "16px",
              }}
            >
              <Spinner variant="dark" animation="border" />
              <span style={{ paddingLeft: "20px" }}>Loading...</span>
            </div>
          </Modal.Body>
        </Modal>
        {/* when we reload on hiting the api spinner with modal spin END*/}



                <div className="container-fulid" style={{ display: "fixed", style: "overflow-x: auto" }}>
                    <div className="row" >
                        <div className="col-md-12" >
                            <div>
                                <Table responsive className="table table-striped">
                                    <thead scope="row" style={{ whiteSpace: "nowrap" }}>
                                        <tr
                                            style={{
                                                background: "rgb(18 80 172)",
                                                color: "white",
                                                position: "sticky",
                                                top: "0px",

                                            }}
                                        >
                                            {/* <th>Status</th>
                                            <th>CID</th>
                                            <th>Contact Name</th>
                                            <th>Company Name</th>
                                            <th>Contact Number</th>
                                            <th>Designation</th>
                                            <th>Company Size</th>
                                            <th>Email ID</th>
                                            <th>Lead Source</th>
                                            <th>Next Follow Up</th>
                                            <th>Stage</th>
                                            <th>Last Updated On</th>
                                            <th>Last Remark</th>
                                            <th>Next Step</th>
                                            <th>Enquired On</th> */}


                                            {
                                                this.props.headerkeys.map((s, index) => {
                                                  if(s!="enquiryid"){
                                                    // console.log(s)
                                                    return (
                                                        <th id={s}>{s.toUpperCase()}</th>
                                                        
                                                    )
                                                   
                                                    }
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
                                        {this.props.header.map((s, index) => {
                                            // console.log(s)

                                            var keys = this.props.headerkeys;
                                            // console.log(s);
                                            return (
                                                <tr key={index} style={{ cursor: "pointer" }} onClick={(e) => this.rowModalOpen(s)}>
                                                    {
                                                        keys.map((data, key) => {

                                                          if(data != "enquiryid"){
                                                          // console.log(data,key)
                                                            if (s[data] == null) {
                                                                s[data] = ''
                                                            }

                                                            if(data == "status"){
                                                              return(
                                                                <td>
                                                                <img style={{width:"50%",height:"auto   "}} src= {`https://crmtest.trackhr.tech/Assets/${s[data]}.png`}/>

                                                                </td>
                                                                

                                                              )
                                                            }
                                                           


                                                            if (typeof s[data] != "object") {
                                                                return <td key={key} id={`${data}0`}dangerouslySetInnerHTML={{ __html: s[data] }}></td>
                                                            }
                                                          }

                                                        })

                                                    }


                                                </tr>
                                            );
                                            // return (
                                            //     <>
                                            //         <tr onClick={(e) => this.rowModalOpen(e)} className="hovereffect" >
                                            //             <td><img src={box} style={{ height: "35px", width: "30px", padding: "2px", hover: "after" }} /></td>
                                            //             <td>{s["customer id"]}</td>
                                            //             <td>{s["customer name"]}</td>
                                            //             <td>{s["company name"]}</td>
                                            //             <td>{s["contact number"]}</td>
                                            //             <td>{s.designation}</td>
                                            //             <td>{s.companysize}</td>
                                            //             <td>{s.emailid}</td>
                                            //             <td>{s.leadsource}</td>
                                            //             <td>{s["next follow up"]}</td>
                                            //             <td>{s.stage}</td>
                                            //             <td>{s["last communicated"]}</td>
                                            //             <td>{s["last remarks"]}</td>
                                            //             <td>{s.nextstep}</td>
                                            //             <td>{s["enquired on"]}</td>
                                            //         </tr>

                                            //     </>


                                            // )
                                        })}
                                    </tbody>
                                    

                                </Table>

                                




                            </div>
                        </div>
                    </div>
                </div>

                {/* row Modal */}
                <div>
          <Modal
          scrollable={true}
          show={this.state.modalshow}
          onHide={this.handleClose}
          keyboard={false}
          backdrop="static"
          style={{backgroundColor:"rgba(0,0,0,0.9)"}}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
             
            >Action</Modal.Title>

            <div style={{display:"flex",width:'100%',justifyContent:"space-around"}}>

            <Tooltip title="Update Follow Up">
  <IconButton onClick={(e) => this.updateModalOpen(e)}>
  <AddTaskSharpIcon/>
  </IconButton>
</Tooltip>

<Tooltip title="Close Enquiry">
  <IconButton onClick={(e) => this.enquiryModalOpen(e)}>
  <DoDisturbOffSharpIcon/>
  </IconButton>
</Tooltip>

<Tooltip title="Call">
  <IconButton id="actionModalContactNumber" onClick={(e) => this.callnumber(e)} >
  <CallIcon/>
  </IconButton>
</Tooltip>

<Tooltip title="Delete">
  <IconButton onClick={(e)=>{this.deleteEnquiry(e)}}>
  <DeleteForeverIcon/>
  </IconButton>
</Tooltip>

<Tooltip title="Edit">
  <IconButton onClick={(e)=>{this.editModalOpen(e)}}>
  <EditIcon/>
  </IconButton>
</Tooltip>
            </div>
          </Modal.Header>
          <Modal.Body>

          {
            this.state.isapihitcomplete ?
             this.state.isdatafound ?
             <>
          <div className="container-fulid" style={{ padding: "0px", style: "overflow-x: auto" }}>
                    <div className="row" >
                        <div className="col-md-12" >
                            <div>
                                <Table responsive className="table table-striped">
                                    <thead scope="row" style={{ whiteSpace: "nowrap" }}>
                                        <tr
                                            style={{
                                                background: "rgb(18 80 172)",
                                                color: "white",
                                                position: "sticky",
                                                top: "0px",

                                            }}
                                        >
                                      


                                            {
                                                this.state.updatekeys.map((s, index) => {
                                                    // console.log(s)
                                                    return (
                                                        <th>{s.toUpperCase()}</th>
                                                        
                                                    )
                                                   

                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
                                        {this.state.updateheaderrow.map((s, index) => {
                                            // console.log(s)

                                            var keys = this.state.updatekeys;
                                            // console.log(s);
                                            return (
                                                <tr key={index} style={{ cursor: "pointer" }} >
                                                    {
                                                        keys.map((data, key) => {
                                                          console.log(data)
                                                            if (s[data] == null) {
                                                                s[data] = ''
                                                            }
                                                            if(data=="remarks"){
                                                              return(
                                                                <td style={{whiteSpace:"normal"}} dangerouslySetInnerHTML={{ __html: s[data] }}></td>
                                                              )
                                                            }

                                                            if (typeof s[data] != "object") {
                                                                return <td key={key} dangerouslySetInnerHTML={{ __html: s[data] }}></td>
                                                            }


                                                        })

                                                    }


                                                </tr>
                                            );
                                         
                                        })}
                                    </tbody>
                                    

                                </Table>

                                




                            </div>
                        </div>
                    </div>
                </div>

                <div style={{margin:"10px 0px"}}>

       
{
  this.state.labelupdate.map((s,index)=>{
    // console.log(s)
    return(
      <>
       <span style={{fontWeight:"500"}}>{s.name}</span> : <span>{s.value}</span> <br></br>
      </>

    )

  })
}
                  
                </div>
                </>
                :
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <h3 style={{ fontFamily: "sans-serif", color: "#eea606" }}>No Records!</h3>
                </div>
              :
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "50vh",
                  alignItems: "center"
                }}
              >
                <Spinner color="red" animation="border" variant="dark" />
              </div>
}
         
          </Modal.Body>
         
        </Modal>
      </div>
            
            {/* update Modal */}
            <div>
          <Modal
          scrollable={true}
          show={this.state.updateshow}
          onHide={this.updateClose}
          keyboard={false}
          backdrop="static"
          style={{backgroundColor:"rgba(0,0,0,0.9)"}}
          centered

        >
          <Modal.Header closeButton>
            <Modal.Title
             
            ></Modal.Title>
            <div><p>Follow up date Update Form</p>
          <h6>Enquiry ID : <span>{this.state.recid}</span></h6></div>

     

            
          </Modal.Header>
          <Modal.Body>
          <Input
        htmlFor={"Next Follow Date"}
	  type={"text"}
        label={" Next Follow Date"}
        labelStyle={{fontWeight:"600"}}
        required={true}
        value={this.state.updatedate}
        onClick={(e) => this.handleChange()}
      />
          <DatePicker
        openDatePicker={this.state.openDatePicker}
        onHideDatePicker={() => this.OpenDatePicker(false)}
        onChange={(date) => this.storeDate(date)}
        defaults={this.state.updatedate}
      />

      {/* stage in update modal */}
      <div className="row">
                     
                      <div className="col-md-12" >
                        <div className="col-md-4">
                          <label htmlFor="marital" style={{fontWeight:"600"}}>
                            Stage<span className="error" style={{color:"red"}}>*</span>
                          </label>
                        </div>
                        <div className="col-md-12">
                          <select
                            className="form-control"
                            name="stageid"
                            value={this.state.stageid}
                            onChange={(e) => this.storestageprogress(e)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {
                            this.state.stageprogress.map((s, index) => {
                                  return (
                                    <option key={index} value={s.stageid}>
                                      {s.stage}
                                    </option>
                                  );
                                })
                              }
                          </select>
                        </div>
                      </div>
                    </div>
         
         {/* next step */}
         <Input
        htmlFor={"Next Step"}
	  type={"text"}
        label={"Next Step"}
        labelStyle={{fontWeight:"600"}}
        value={this.state.nextstep}
        onChange={(e) => this.setState({nextstep:e.target.value})}
      />

      {/* remarks */}
      <Input
        htmlFor={"Remark"}
	  type={"text"}
        label={"Remark"}
        labelStyle={{fontWeight:"600"}}
        value={this.state.remark}
        onChange={(e) => this.setState({remark:e.target.value})}
      />
          </Modal.Body>

          <Modal.Footer style={{justifyContent:"center"}}>
          <Button  buttonTitle='Submit'
      onClick={(e) =>this.handleupdatebutton()}
      />
          </Modal.Footer>
         
        </Modal>
      </div>

      {/* close equiry form */}
      <div>
          <Modal
          scrollable={true}

          show={this.state.enquiryshow}
          onHide={this.enquiryClose}
          keyboard={false}
          backdrop="static"
          style={{backgroundColor:"rgba(0,0,0,0.9)"}}
          centered

        >
          <Modal.Header closeButton>
            <Modal.Title
             
            ></Modal.Title>
             <div><p>Close Enquiry Form</p>
          <h6>Enquiry ID : <span>{this.state.recid}</span></h6></div>
            

            
          </Modal.Header>
          <Modal.Body>
             {/* stage in update modal */}
      <div className="row">
                     
                     <div className="col-md-12" >
                       <div className="col-md-4">
                         <label htmlFor="Enquiry Conclusion" style={{fontWeight:"600"}}>
                         Enquiry Conclusion<span className="error" style={{color:"red"}}>*</span>
                         </label>
                       </div>
                       <div className="col-md-12">
                      
                         <select
                            className="form-control"
                            name="conslusionvalue"
                            value={this.state.stageid}
                            onChange={(e) => this.storeconclusionvalue(e)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {
                            this.state.Stageconclusion.map((s, index) => {
                              // console.log(s)
                                  return (
                                    <option key={index} value={s.stageid}>
                                      {s.stage}
                                    </option>
                                  );
                                })
                              }
                          </select>
                       </div>
                     </div>
                   </div>
        
        {/* next step */}
        <Input
       htmlFor={"Closing Remarks"}
   type={"text"}
       label={"Closing Remarks"}
       labelStyle={{fontWeight:"600"}}
       value={this.state.ClosingRemarks}
       onChange={(e) => this.setState({ClosingRemarks:e.target.value})}
     />
         
          </Modal.Body>
          <Modal.Footer style={{justifyContent:"center"}}>
          <Button  buttonTitle='Submit'
      onClick={(e) =>this.handleenquiryclosebutton()}
      />
          </Modal.Footer>
        </Modal>
      </div>

      {/* edit enquiry form */}
      <div>
          <Modal
          // size="lg"
          scrollable={true}

          fullscreen
          show={this.state.editshow}
          onHide={this.editClose}
          keyboard={false}
          backdrop="static"
          style={{backgroundColor:"rgba(0,0,0,0.9)"}}
          centered

        >
          <Modal.Header closeButton>
            <Modal.Title
             
            ></Modal.Title>

<div><p>Edit Enquiry Form</p>
          <h6>Enquiry ID : <span>{this.state.recid}</span></h6></div>

            
          </Modal.Header>
          <Modal.Body>
          


          <form onSubmit={this.handleSubmit}> 
                 
    <div className ='container-fluid'>
        <div className="row">
            <div className="col-md-3">
        <label style={{fontWeight:"600",fontSize:"16px"}}>Marketed by</label><span style={{color:"red"}}>*</span>
        <select  id="Search"  disabled="true"  value={this.state.Marketedby} name="Search" cursor="no-drop" className="form-control" onChange={(e)=>this.Marketedby(e)}  style={{backgroundColor:"#e8e5e5",cursor:"not-allowed"}} > 
                                              <option value="" ></option>
                                              {
                                              this.state.AgentList.map((s, index) => {
                                                return (
                                                  <option key={index} value={s.agentid}>
                                                    {s.agentname}
                                                  </option>
                                                     );
                                                  })
                                             }
                                              </select>


           </div>
         </div>
            <div className="col-sm-3">

           <div className="col-sm-5">
            </div>
            </div>
           
                                   
                                      <h2 style={{margin:"10px 0px"}}>Customer Details</h2>
                                      <hr></hr>
                                      <div className='row'>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Company Name"
                                              placeholder=" Enter Company Name" 
                                              required={true}
                                              onChange={(e)=>this.companyname(e)}
                                              value={this.state.companyname}
                                               />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Company Size"
                                              type="number"
                                              placeholder="Enter Company Size"
                                              required={true}
                                              onChange={(e)=>this.companysize(e)}
                                              value={this.state.companysize}
                                              />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Contact Person"
                                              placeholder="Enter Contact Person "
                                              required={true}
                                              onChange={(e)=>this.contactperson(e)}
                                              value={this.state.contactperson}
                                              />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Contact Number"
                                              required={true}
                                              placeholder="Enter Contact Number"
                                              type="text"
                                              wordboundation={{limit:10,id:"descriptionInput"}}
                                              onChange={(e)=>this.contactnumber(e)}
                                              value={this.state.contactnumber}
                                              />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="E.mail"
                                              placeholder="Enter E.mail "
                                              onChange={(e)=>this.email(e)}
                                              value={this.state.email}
                                              />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Designation"
                                              placeholder="Enter Designation"
                                              onChange={(e)=>this.designation(e)}
                                              value={this.state.designation}
                                              />
                                          </div>
                                          <div className='col-md-3'>
                                              <Input
                                              label="Office address "
                                              placeholder="Enter Office Address"
                                              onChange={(e)=>this.officeaddress(e)}
                                              value={this.state.officeaddress}
                                              />
                                          </div>
                                          <div className='col-md-3' style={{marginTop:"8px"}}>
                                              
                                              <label >Lead Source</label><span style={{color:"red"}}>*</span>
                                              <select id="Search" name="Search" className="form-control" onChange={(e)=>this.leadsourceid(e)} style={{backgroundColor:"#e8e5e5"}} value={this.state.leadsourceid}> 
                                              <option value="">---Select----</option>
                                          {
                                              this.state.LeadSource.map((s, index) => {
                                                return (
                                                  <option key={index} value={s.leadsourceid}>
                                                    {s.leadsource}
                                                  </option>
                                                );
                                              })
                                          }
                                              </select>
                                          </div>
                                         
                                      </div>
                                      <h2 style={{margin:"10px 0px"}}>Customer Enqiuery</h2>
            <hr></hr>
                         <div className="row">
                             <div className="col-md-3">
                             <Input
                             label="App user count"
                             required={true}
                             type="number"
                             value={this.state.appusercount}
                             placeholder="Enter App User Count"
                             onChange={(e) => this.storeappusercount(e)}
                             />
                             </div>

                             <div className="col-md-3">
                             <Input
                             label="Machine user count"
                             id="machinecountid"
                             placeholder="Enter Machine User Count"
                             required={true}
                             type="number"
                             value={this.state.machineusercount}
                             onChange={(e)=> this.storemachineusercount(e)}
                             />
                                 </div>
                             <div className="col-md-3">
                             <Input
                                label="Remarks"
                                 placeholder="enter remarks"
                                onChange={(e)=> this.storeremark(e)}
                                value={this.state.editremark}

                             />
                             </div>
                               <div  id='attendance-info' className ="row" style={{display:this.state.machineusercount==1?"flex":"none"}}>
                                   <div className="col-md-3" style={{marginTop:"10px"}}>
                                   <label>Attendance machine</label>
                                   <select onChange={(e)=>this.attendancemachine(e)} style={{backgroundColor:"#e8e5e5"}}value={this.state.attendancemachine} id="Modal-AttendanceMachine" name="AttendanceMachine" className="form-control" >             
																							<option value="None">None</option>
																							<option value="FingerPrint">FingerPrint</option>
																							<option value="RFID">RFID</option>                                                                             
									
                                    													</select>
                                   </div>
                               

                               <div className="col-md-3">
                                   <Input
                                   label="Attendance Machine Quality"
                                   type="number"
                                  onChange={(e)=> this.attendancemachinequality(e)}
                                  value={this.state.attendancemachinequality}

                                   />
                               </div>
                               <div id = "RFIDShow" style={{display:this.state.attendancemachine=="RFID"?"block":"none",marginTop:"40px"}} className="col-sm-3">
                               <input type="checkbox" checked={this.state.IncludeCards} id="Modal-IncludeCards" onChange={(e)=>this.checkboxchange(e)}/>
							<label htmlFor="Modal-IncludeCards">Include Cards</label>
									</div>  
                                    
                        </div>
                               </div>      
                                

                                                                          <div className="row">
																			<div className="col-sm-5">
																			</div>
																			<div className="col-sm-2">
                                      <Button  buttonTitle='Submit'
      onClick={(e) =>this.editformsubmit(e)}
      />
																			</div>
                                                                            
																			<div className="col-sm-5">
																		</div>
					                    									</div>	
                                                                
</div>
 

</form>

         
          </Modal.Body>
   
        </Modal>
      </div>
      {/* edit enquiry form end */}
            </>
        );

    }

}



export default Show;