import React, { Component } from 'react'
import Classes from './style.module.css'
export default class Thousandthree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defVal: [],
            value: [], isUpdating: false
        }
        this.storeValue = this.storeValue.bind(this)
    }
    componentDidMount() {
        const getValues = (data) => {
            console.log(data)
            var values = data
            if (values.indexOf(',') > -1) {
                var arr = values.split(',')
                return arr
            }
        }
        const getDefaultValuesArr = (data) => {
            var values = data
            if (values != undefined) {
                if (values.indexOf(',') > -1) {
                    var arr = values.split(',')
                    return arr
                }
            }
        }

        this.setState({
            defVal: getDefaultValuesArr(this.props.defdata),
            value: getValues(this.props.filterData)
        },() => {
            this.setState({isUpdationDone:true})
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.filterData != this.props.filterData && prevProps.defdata != this.props.defdata) {
            this.setState({isUpdationDone:false})
            const getValues = (data) => {
                console.log(data)
                var values = data
                if (values.indexOf(',') > -1) {
                    var arr = values.split(',')
                    return arr
                }
            }
            const getDefaultValuesArr = (data) => {
                var values = data
                if (values != undefined) {
                    if (values.indexOf(',') > -1) {
                        var arr = values.split(',')
                        return arr
                    }
                }
            }

            this.setState({
                defVal: getDefaultValuesArr(this.props.defdata),
                value: getValues(this.props.filterData)
            },() => {
                this.setState({isUpdationDone:true})
            })
        }
    }
    storeValue(e) {
        console.log(e.target.value)
        var arr = []
        if (this.state.defVal != undefined) {
            arr = this.state.defVal
        }
        if (e.target.checked == true) {
            console.log('light')
            arr.push(e.target.value)
            this.props.onChange(arr.join())
        } else {
            console.log('white')
            arr.splice(arr.indexOf(e.target.value), 1)
            this.props.onChange(arr.join())
        }
        // let arr = this.state.defVal.filter(val => val == e.target.value)
        // var arr = this.state.defVal
        // arr.splice(arr.indexOf(e.target.value), 1)
        // console.log(arr)
        // this.props.onChange(arr.join())
    }
    render() {
        // console.log(this.props)
        const getID = (val) => {
            if (val.indexOf(' ') > -1) {
                return val.replace(' ', '') + (new Date()).getTime()
            } else {
                return val + (new Date()).getTime()
            }
        }
        return (
            <>
                {
                    this.state.isUpdationDone ?
                        <div className={'row ' + Classes.overflow} style={{ maxHeight: "370px", overflow: "auto" }}>
                            {
                                this.state.value.map((val, index) => {
                                    var check = false
                                    if (this.state.defVal != undefined) {
                                        for (let i = 0; i < this.state.defVal.length; i++) {
                                            if (this.state.defVal[i] == val) {
                                                check = true
                                                break
                                            }
                                        }
                                    }
                                    console.log(val, check)

                                    return (
                                        <>
                                            <div className="col-md-6" key={index} style={{ display: 'flex', alignItems: "center", marginBottom: "5px" }}>
                                                <input type="checkbox" id={getID(val)} value={val} name={val} defaultChecked={check} onChange={(e) => this.storeValue(e)} style={{ width: "20px", height: "20px" }} />
                                                <label htmlFor={getID(val)} style={{ paddingLeft: "10px", cursor: "pointer" }}>{val}</label>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        :
                        "loading"
                }
            </>
        )
    }
}
