import React, { Component } from 'react'
// import InputBox from './InputBox/InputBox'
export default class Thousandfour extends Component {
    constructor(props){
        super(props)
        const getDate = (props) => {
            var defStr = this.props.defdata
            var arr = defStr.split(' ')[0].split('-')
            return arr[0]+'-'+arr[1]
        }
        this.state={
            date:getDate()
        }
        this.storeMonth = this.storeMonth.bind(this)
    }
    storeMonth(e){
        if(e.target.value != ""){
            console.log(new Date)
            var todayDate = new Date()
            if(todayDate.getDate() > 10){
                var date = e.target.value+'-'+todayDate.getDate()+' 00:00:00.000'
                this.props.onChange(date)
            }else{
                var date = e.target.value+'-0'+todayDate.getDate()+' 00:00:00.000'
                this.props.onChange(date)
            }
        }
        this.setState({date:e.target.value})
    }
    render() {
        const style={
            border:"none",
            background:'none',
            paddingLeft:'0px',
            marginBottom:'15px'
        }
        return (
            <>
            <div className='form-group' style={{paddingBottom:"10px"}}>
                <label htmlFor='monthyear' style={{fontWeight:'600',fontSize:'18px'}}>Select Month</label>
                <input style={style} type="month" id='monthyear' defaultValue={this.state.date} className='form-control' onChange={(e) => this.storeMonth(e)}/>
            </div>
            {/* {
                this.state.defVal.map((val,index)=>{
                    return (
                        <InputBox
                            htmlFor={val.name}
                            label={val.name}
                            required={true}
                            type="month"
                            // placeholder="Enter first name"
                            // value={this.state.FirstName}
                            defaultValue={val.default}
                            // onChange={(e) => {this.setState({ FirstName: (e.target.value).charAt(0).toUpperCase() + (e.target.value).slice(1) })}}
                        />
                    )
                })
            } */}
            </>
        )
    }
}
