import React, { Component } from 'react'
import './Thousandone.css'
export default class Thousandone extends Component {
    constructor(props){
        super(props)
        const getDate = (props) => {
            var defStr = this.props.defdata
            var arr = defStr.split(' ')
            return arr[0]
        }
        this.state={
            date:getDate()
        }
        this.storeDate = this.storeDate.bind(this)
    }
    storeDate(e){
        if(e.target.value != ""){
            var date = e.target.value+' 00:00:00.000'
            this.props.onChange(date)
        }
        this.setState({date:e.target.value})
    }
  render() {
    const style={
        border:"none",
        background:'none',
        paddingLeft:'0px',
        marginBottom:'15px'
      }
    return (
        <>
        <div className='form-group' style={{paddingBottom:"10px"}}>
            <label htmlFor='startDate' style={{fontWeight:'600',fontSize:'18px'}}>Select Date</label>
            <input style={style} type="date" id='startDate' defaultValue={this.state.date} className='form-control' onChange={(e) => this.storeDate(e)}/>
        </div>
        </>
    )
  }
}

