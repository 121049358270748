import React, { Component } from "react";
import trackhrlogo from "./trackhrlogo.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Input } from "./HexInput/InputBox";
import Hexapi from "./HexAPI/Hexapi";
import Show from "./show.js";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import "./table.css";
import { Spinner } from "react-bootstrap";
import Button from "./HexButton/button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Filter from "./Filters/Filter";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import buildnumber from "./buildNumber.json";
import Dashboard from './Charts/Dashboard'
import './App.css'

// sessionStorage.setItem("Apipathurl", "Https://hexbss.xyz/crmreact/");
// sessionStorage.setItem("agentid", "3");

class App extends Component {
  constructor() {
    super();
    this.state = {
      agentid: sessionStorage.getItem("agentid"),
      value: "0",
      leadtypes: "0",
      filter: "-1",
      limit: 100,
      searchdata: "",
      storesearch: " ",
      pagenumber: "1",
      totalpagenumber: [],
      header: [],
      headerkeys: [],
      isenquirypageshowComplete: false,
      isDataAvailable: false,
      isenquirycountshowcomplete: false,
      newiframe: false,
      logoutshow: false,

      selectedpage:"1",

      // filter in modal
      filters: [],
      //   filters: [
      //     {
      //         DefVal: `hello`,
      //         FilterName: 'Date',
      //         FilterType: 1001,
      //         Values: `bye`
      //     },
      //     {
      //         DefVal: 'All Team',
      //         FilterName: 'Employees',
      //         FilterType: 1002,
      //         Values: 'My Subordinates,All Team'
      //     }
      // ]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    // this.enquirypageshow = this.enquirypageshow.bind(this)
    this.enquirycount = this.enquirycount.bind(this);
    this.clearsearch = this.clearsearch.bind(this);
    this.leadstablerowclick = this.leadstablerowclick.bind(this);
    this.logoutClose = this.logoutClose.bind(this);
    // this.setshow = this.setshow.bind(this)
  }

  enquirycount(agentid, searchdata, leadtypes, filter, limit) {
    console.log("tabledata");
    let obj = {
      query: `[dbo].[CRM_Proc_Leads_Count] @AgentID='{0}', @Searchdata ='{1}', @LeadType='{2}',@Filter='{3}',@Limit='{4}'`,
      queryArr: [
        `${this.state.agentid}`,
        `${this.state.searchdata}`,
        `${this.state.leadtypes}`,
        `${this.state.filter}`,
        `${this.state.limit}`,
      ],
    };
    Hexapi(obj).then((resp) => {
      console.log(resp);
      console.log(resp[""]);
      this.setState({ pagenumber: "1", isenquirycountshowcomplete: true });

      var pagenumber = Math.ceil(resp[""][0].cntrows / this.state.limit);

      console.log(pagenumber);
      let arr = [];
      for (var i = 1; i <= pagenumber; i++) {
        arr.push(i);
      }
      this.setState({ totalpagenumber: arr }, () => {
        console.log(this.state.totalpagenumber);
      });
    });
  }

  // enquirypageshow(pagenumber, agentid, searchdata, leadtypes, filter, limit) {
  //   this.setState({ isenquirypageshowComplete: false, isDataAvailable: false })
  //   let obj = {
  //     'query': `[dbo].[CRM_Proc_EnquiryTable_PageShow] @pageno='{0}', @AgentID='{1}', @Searchdata ='{2}', @LeadType='{3}',@Filter='{4}',@Limit='{5}'`,
  //     'queryArr': [
  //       `${this.state.pagenumber}`,
  //       `${this.state.agentid}`,
  //       `${this.state.searchdata}`,
  //       `${this.state.leadtypes}`,
  //       `${this.state.filter}`,
  //       `${this.state.limit}`,
  //     ]
  //   }
  //   Hexapi(obj).then(resp => {
  //     console.log(resp[""])
  //     if (resp[''].length > 0 && resp[''] != null) {
  //       var keys = Object.keys(resp[''][0]);
  //       console.log(keys)
  //       this.setState({ headerkeys: keys, header: resp[""], isDataAvailable: true })
  //     } else {
  //       this.setState({ isDataAvailable: false })
  //     }
  //   }).then(resp => {
  //     this.setState({ isenquirypageshowComplete: true })

  //   })
  // }

  handleChange(e, newvalue) {
    e.preventDefault();
    // console.log(newvalue)
    if (newvalue != "Dashboard") {
      this.setState({ leadtypes: newvalue, value: newvalue }, () => {
        this.filterdataapi("-1");

        // this.enquirypageshow(this.state.pagenumber, this.state.agentid, this.state.searchdata, this.state.leadtypes, this.state.filter, this.state.limit)
        this.enquirycount(
          this.state.agentid,
          this.state.searchdata,
          this.state.leadtypes,
          this.state.filter,
          this.state.limit
        );
      });
    } else {
      this.setState({isDataAvailable:true,isenquirypageshowComplete:true, isenquirycountshowcomplete:true,value: newvalue })
    }
  }

  leadstablerowclick(e) {
    this.setState({ show: true });
  }

  clearsearch(e) {
    this.setState({ searchdata: "" }, () => {
      this.filterdataapi("-1");
      this.enquirycount(
        this.state.agentid,
        this.state.searchdata,
        this.state.leadtypes,
        this.state.filter,
        this.state.limit
      );
    });
  }

  handleClick(e) {
    console.log(e);
    var searchdatastore = this.state.searchdata;
    console.log(searchdatastore);
    this.setState({ searchdata: searchdatastore });
    console.log(this.state.searchdata);
    this.filterdataapi("-1");

    // this.enquirypageshow(this.state.pagenumber, this.state.agentid, this.state.searchdata, this.state.leadtypes, this.state.filter, this.state.limit)
    this.enquirycount(
      this.state.agentid,
      this.state.searchdata,
      this.state.leadtypes,
      this.state.filter,
      this.state.limit
    );
  }
  filter(e) {
    console.log(e.target.value);
    this.setState({ filter: e.target.value }, () => {
      this.filterdataapi("-1");

      // this.enquirypageshow(this.state.pagenumber, this.state.agentid, this.state.searchdata, this.state.leadtypes, this.state.filter, this.state.limit)
      this.enquirycount(
        this.state.agentid,
        this.state.searchdata,
        this.state.leadtypes,
        this.state.filter,
        this.state.limit
      );
    });
  }

  storesearch(e) {
    console.log(e.target.value);
    this.setState({ searchdata: e.target.value });
    console.log(this.state.searchdata);
  }

  pageclick(s) {
    console.log(s);
    // var page =document.getElementById(s);
    // console.log(page)
    // page.style.background="rgb(18, 80, 172)"
    this.setState({selectedpage:s})
    this.setState({ pagenumber: s }, () => {
      this.filterdataapi("-1");

      // this.enquirypageshow(this.state.pagenumber, this.state.agentid, this.state.searchdata, this.state.leadtypes, this.state.filter, this.state.limit)
      this.enquirycount(
        this.state.agentid,
        this.state.searchdata,
        this.state.leadtypes,
        this.state.filter,
        this.state.limit
      );
    });
  }

  filterdataapi(filter) {
    this.setState({ isenquirypageshowComplete: false, isDataAvailable: false });

    let obj = {
      query: `[dbo].[CRM_Proc_Leads_GetList] @PageNo='{0}', @AgentID='{1}', @Searchdata ='{2}', @LeadType='{3}',@Filter='{4}',@Limit='{5}'`,
      // 'query': `[dbo].[CRM_Proc_Leads_GetList] @PageNo ='1',@AgentID ='1',@Searchdata ='-1',@LeadType ='0',@Filter ='-1',@Limit = '20'`,
      queryArr: [
        `${this.state.pagenumber}`,
        `${this.state.agentid}`,
        `${this.state.searchdata}`,
        `${this.state.leadtypes}`,
        `${filter}`,
        `${this.state.limit}`,
      ],
    };
    Hexapi(obj)
      .then((resp) => {
        console.log(resp);
        console.log(resp.filters);

        this.setState({ filters: resp.filters });

        if (resp.reportdata != null) {
          var keys = Object.keys(resp.reportdata[0]);
          console.log(keys);
          this.setState({
            headerkeys: keys,
            header: resp.reportdata,
            isDataAvailable: true,
          });
        } else {
          this.setState({ isDataAvailable: false });
        }
      })
      .then((resp) => {
        this.setState({ isenquirypageshowComplete: true });
      });
  }

  componentDidMount() {
    if (
      sessionStorage.getItem("console") == undefined &&
      !sessionStorage.getItem("console")
    ) {
      console.log = () => { };
    }
    this.enquirycount(this.state.agentid, "-1", "0", "1", "100");
    // this.enquirypageshow(this.state.pagenumber, this.state.agentid, this.state.searchdata, this.state.leadtypes, this.state.filter, this.state.limit)
    this.filterdataapi("-1");
  }

  clearFilters() {
    this.setState({ isFilterModalOpen: false });
    this.filterdataapi("-1");
  }

  ApplyFilter(filterString) {
    this.setState({ isFilterModalOpen: false });
    this.filterdataapi(filterString);

    // if (filterString.indexOf('$#12$$') > -1) {
    //     let filterArr = filterString.split('$#12$$')
    //     let subordinateMode = ''
    //     if (filterArr[1] == "All Team") {
    //         subordinateMode = '1'
    //     } else {
    //         subordinateMode = '0'
    //     }
    //     this.setState({
    //         LogtimeString: filterArr[0],
    //         subordinateMode: subordinateMode,
    //         DateString: this.setDate(filterArr[0])
    //     })
    //     console.log(filterString)
    //     this.loadDailyWorkReport(this.state.selectedMode, filterArr[0], subordinateMode)
    // }
  }

  handleOpennew(e) {
    console.log("hello new");
    this.setState({ newiframe: true });
  }

  Logoutpage() {
    console.log("logout page");
    this.setState({ logoutshow: true });
  }

  logoutClose() {
    console.log("logoutshow false");
    this.setState({ logoutshow: false });
  }

  ExitLogout() {
    console.log("exitlogout");
    confirmAlert({
      message: "Are you sure you want to Logout? ",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({
              isModalQueryHitComplete: false,
            });

            window.location.href=`${sessionStorage.getItem("loginurl")}login.php`
            // let obj = {
            //   'query': `[dbo].[CRM_Proc_Updatefollowup_Submit]@RecId='{0}',@FollowupDate='{1}',@Stage='{2}',@NextStep='{3}',@Remarks='{4}'
            // `,
            //   'queryArr': [`${this.state.recid}`,`${this.state.updatedate}`,`${this.state.stageid}`,`${this.state.nextstep}`,`${this.state.remark}`]
            // }
            // Hexapi(obj).then(resp => {
            //   this.setState({
            //     isModalQueryHitComplete: true,
            //   });
            //   console.log(resp)
            //   console.log(resp[''])

            //   if (
            //     resp[""][0]
            //       .command == "0"
            //   ) {

            //   }else{
            //     Swal.fire({
            //       text: resp[""][0].msg,
            //     }).then((resp)=>{
            //       window.location.reload()
            //     })
            //   }

            // })
          },
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "confirm__overlayClass",
    });
  }


  getcallback(value){
    if(value){
      this.filterdataapi("-1");
    }
  }
  render() {
    console.log(`crm ${buildnumber.buildNumber}`);

    return (
      <>
        <div className="container-fluid">
          <div
            style={{
              backgroundColor: "white",
              boxShadow: "4px 4px 9px gray",
              position: "sticky",
              top: "0",
            }}
            className="row"
          >
            <div className="col-md-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img src={trackhrlogo} width="40" height="auto" alt="" />
                {/* <h1 style={{ color: "#1250ac", fontSize: "24px" }}>
                  Track<span style={{ fontWeight: "bold" }}>Hr</span>
                </h1> */}
              </div>
            </div>
            <div className="col-md-8">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={this.state.value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={(e, value) => this.handleChange(e, value)}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Dashboard" value="Dashboard" />
                      <Tab label="Active Leads" value="0" />
                      <Tab label="Subscribe Leads" value="1" />
                      <Tab label="Lost Leads" value="-1" />
                      <Tab label="All Leads" value="2" />
                    </TabList>
                  </Box>

                  <TabPanel value="Dashboard"></TabPanel>
                  <TabPanel value="0"></TabPanel>
                  <TabPanel value="1"></TabPanel>
                  <TabPanel value="-1"></TabPanel>
                  <TabPanel value="2"></TabPanel>
                </TabContext>
              </Box>
            </div>
            <div className="col-md-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <a
                  href={`${sessionStorage.getItem("url")}customerenquiry?ag=${this.state.agentid
                    }`}
                  target="_blank"
                >
                  <Button buttonTitle="New" />
                </a>
                <i
                  className="fa fa-address-book"
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  aria-hidden="true"
                  onClick={(e) => this.Logoutpage(e)}
                ></i>
                {/* update Modal */}
                <div>
                  <Modal
                    show={this.state.logoutshow}
                    onHide={this.logoutClose}
                    keyboard={false}
                    backdrop="static"
                    style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>Manish Nagar</p>
                    </Modal.Body>

                    <Modal.Footer style={{ justifyContent: "center" }}>
                  
                        <Button
                          buttonTitle="Logout"
                          onClick={(e) => this.ExitLogout()}
                        />
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              {/* <button type="Submit" style={{ Margintop: "5px", height: "40px", Width: "100px", backgroundColor: "#337ab7", borderColor: "transparent", Margintop: "8px", color: "white", borderRadius: "7px" }}>NEW</button>
            <i className="fa fa-address-book" style={{ fontSize: "30px" }} aria-hidden="true"></i> */}
            </div>
          </div>
        </div>
        {/* after nav bar */}

        <div className="container-fluid" style={{height:"92vh",overflow:"auto"}}>
          {
            this.state.value != "Dashboard" ?
              <div className="row" style={{ margin: "20px 0px" }}>
                <div className="col-md-4">
                  <div style={{ display: "flex" }}>
                    <Input
                      label="Search"
                      id="Searchdata"
                      labelStyle={{ fontWeight: "600" }}
                      required={true}
                      value={this.state.searchdata}
                      inline={true}
                      onChange={(e) => this.storesearch(e)}
                    />
                    <button
                      style={{
                        Margintop: "5px",
                        height: "40px",
                        Width: "100px",
                        backgroundColor: "rgb(18 80 172)",
                        borderColor: "transparent",
                        color: "white",
                        borderRadius: "7px",
                      }}
                    >
                      <i
                        className="fa fa-search"
                        onClick={(e) => this.handleClick(e)}
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>

                <div className="col-md-2" style={{visibility:this.state.searchdata == ""?"hidden":"visible"}}>
                  <Button
                    onClick={(e) => this.clearsearch(e)}
                    className="clearbutton"
                    buttonTitle="Clear Search"
                    
                  />
                </div>

                <div className="col-md-6">
                  {/* <IconButton onClick={() => this.setState({ isFilterModalOpen: true })}> */}
                  <div style={{ float: "right" }}>
                    <Tooltip title="Filter">
                      <FilterAltIcon
                        style={{ cursor: "pointer", color: "#1250ac" }}
                        onClick={() => this.setState({ isFilterModalOpen: true })}
                      />
                    </Tooltip>
                  </div>
                  {/* </IconButton>  */}

                  {this.state.isFilterModalOpen ? (
                    <Filter
                      isOpenModal={this.state.isFilterModalOpen}
                      onClose={() => this.setState({ isFilterModalOpen: false })}
                      filters={this.state.filters}
                      applyFiltes={(filterString) => this.ApplyFilter(filterString)}
                      clearFilters={() => this.clearFilters()}
                      isGoingBack={this.props.isRefreshFilters}
                      isStopRefreshing={() => this.props.isStopRefreshing()}
                    />
                  ) : null}
                </div>
              </div>
              :
              null
          }

          {/* <div style={{ padding: "20px" }} onChange={(e) => this.filter(e)}>
          <input type="radio" style={{ paddingleft: "10px" }} name="imgsel" value="1" defaultChecked /> <label style={{ fontSize: "19px", padding: "10px", fontWeight: "600" }}>Delayed Follow Up</label>
          <input type="radio" style={{ paddingleft: "10px" }} name="imgsel" value="2" /> <label style={{ fontSize: "19px", padding: "10px", fontWeight: "600" }}>Latest Follow Up</label>
          <input type="radio" style={{ paddingleft: "10px" }} name="imgsel" value="3" /> <label style={{ fontSize: "19px", padding: "10px", fontWeight: "600" }}>Oldest Pending Follow Up</label>
        </div> */}

          <div>
            {this.state.isenquirypageshowComplete ? (
              this.state.isenquirycountshowcomplete ? (
                this.state.isDataAvailable ?
                  this.state.value != "Dashboard" ?
                    <Show
                      headerkeys={this.state.headerkeys}
                      header={this.state.header}
            callfunction={this.getcallback.bind(this)} 

                    />
                    :
                    <Dashboard />
                  : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <h3 style={{ fontFamily: "sans-serif", color: "#eea606" }}>
                        No Records!
                      </h3>
                    </div>
                  )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: "50vh",
                    alignItems: "center",
                  }}
                >
                  <Spinner color="red" animation="border" variant="dark" />
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "50vh",
                  alignItems: "center",
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            )}
          </div>
          {
            this.state.value != 'Dashboard' ?
              <div style={{ paddingTop: "20px" }}>
                {this.state.totalpagenumber.map((s, index) => {
                  return (
                    <button
                      id={s}
                      onClick={() => this.pageclick(s)}
                      style={{
                        Margintop: "25px",
                        marginRight: "5px",
                        Width: "100px",
                        backgroundColor: "lightgrey",
                        borderColor: "transparent",
                        color: "white",
                        borderRadius: "7px",
                        background:this.state.selectedpage == s?"rgb(18, 80, 172)":"gray"
                      }}
                      key={index}
                    >
                      {s}
                    </button>
                  );
                })}
              </div>
              :
              null
          }
        </div>

        {/* iframe */}

        {/* {this.state.newiframe == true ?
 <iframe 
 className='responsive_iframe'
 allowTransparency="true"
 src=""
 ></iframe>
 :null
 
} */}
      </>
    );
  }
}
export default App;
