import React, { Component } from 'react'
import './filter.css'
import { Modal } from 'react-bootstrap'
import Thousand from './filters/Thousand'
import Thousandone from './filters/Thousandone';
import Thousandtwo from './filters/Thousandtwo';
import Thousandthree from './filters/Thousandthree';
import Thousandfour from './filters/Thousandfour';

var defaultValue = []
export default class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: null, FilterName: '',
    }
    this.setFilter = this.setFilter.bind(this)
    this.updateDefaultValues = this.updateDefaultValues.bind(this)
    this.applyFiltes = this.applyFiltes.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
  }
  setFilter(data) {
    this.setState({ filterData: data, FilterName: data.filtername })
  }
  componentDidMount() {
    // defaultValue = []
    if(this.props.isGoingBack){
      defaultValue = []
      this.props.isStopRefreshing()
    }
    console.log(this.props.filters)
    if (this.props.filters != null) {
      this.props.filters.map((data, index) => {
        console.log(data)
        if (index == 0) {
          this.setState({ filterData: data, FilterName: data.filtername })
        }
      })
    }
    var obj = { values: {}, def: {}, oldValues: {} }
    if (this.props.filters != undefined) {
      for (var i = 0; i < this.props.filters.length; i++) {
        obj.values[this.props.filters[i].filtername] = this.props.filters[i].values
        obj.def[this.props.filters[i].filtername] = this.props.filters[i].defval
        obj.oldValues[this.props.filters[i].filtername] = this.props.filters[i].defval
      }
      defaultValue.push(obj)
      console.log("updated Default data", defaultValue)
    }
    console.log('update 4')
  }

  applyFiltes(e) {
    e.preventDefault()
    if (Object.keys(defaultValue[0].def).length != 0) {
      // var fd = new FormData();
      var filterStr = ''
      var keys = Object.keys(defaultValue[0].def)
      for (var i = 0; i < keys.length; i++) {
        if (i < keys.length - 1) {
          filterStr += defaultValue[0].def[keys[i]] + '$#12$$'
        } else {
          filterStr += defaultValue[0].def[keys[i]]
        }
      }

      // console.log(filterStr)
      this.props.applyFiltes(filterStr)
    }
    console.log(defaultValue)
    console.log('update 3')
  }
  updateDefaultValues(data) {
    console.log(this.state.FilterName, data)
    defaultValue[0].def[this.state.FilterName] = data
    console.log(defaultValue)
    console.log('update 1')
  }
  clearFilters() {
    console.log(this.props.filters.length)
    for (var i = 0; i < this.props.filters.length; i++) {
      defaultValue[0].def[this.props.filters[i].filtername] = defaultValue[0].oldValues[this.props.filters[i].filtername]
    }
    console.log('update 2')
    console.log("updated Default data", defaultValue)
    this.props.onClose()
    this.props.clearFilters()
  }
  // componentDidUpdate(){
  //   if(this.props.isGoingBack == true){
  //     this.clearFilters()
  //   }
  //   console.log(this.props.isGoingBack)
  // }
  render() {
    return (
      <>
        {/* filter modal start */}
        <Modal size="lg" centered style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={this.props.isOpenModal} onHide={() => this.props.onClose()} keyboard={false} backdrop='static' >{/*  keyboard={false} backdrop='static' */}
          <Modal.Header>
            <h3>Filters</h3>
            <div style={{ display: "flex", justifyContent: "space-between", width: "250px" }}>
              <div className="btn btn-warning" style={{ color: "#fff" }} onClick={(e) => this.clearFilters(e)}>Clear Filters</div>
              <div className="btn btn-info" style={{ color: "#fff" }} onClick={(e) => this.applyFiltes(e)}>Apply Filters</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-4">
                <div className='row filternames'>
                  {
                    this.props.filters != null ?
                      this.props.filters.map((data, index) => {
                        return (
                          <div key={index} className={'col-12' + (data.filtername == this.state.FilterName ? " active" : '')} onClick={() => this.setFilter(data)}>{data.filtername}</div>
                        )
                      })
                      : ""
                  }
                </div>
              </div>
              <div className="col-8" style={{ maxHeight: "400px", overflow: "hidden" }}>
                {
                  this.state.filterData != null ?
                    this.state.filterData.filtertype == 1000 ?
                      <Thousand defdata={defaultValue[0].def[this.state.filterData.filtername]} onChange={(val) => this.updateDefaultValues(val)} />
                      :
                      this.state.filterData.filtertype == 1001 ?
                        <Thousandone defdata={defaultValue[0].def[this.state.filterData.filtername]} onChange={(val) => this.updateDefaultValues(val)} />
                        :
                        this.state.filterData.filtertype == 1002 ?
                          <Thousandtwo defdata={defaultValue[0].def[this.state.filterData.filtername]} filterData={defaultValue[0].values[this.state.filterData.filtername]} onChange={(val) => this.updateDefaultValues(val)} />
                          :
                          this.state.filterData.filtertype == 1003 ?
                            <Thousandthree defdata={defaultValue[0].def[this.state.filterData.filtername]} filterData={defaultValue[0].values[this.state.filterData.filtername]} onChange={(val) => this.updateDefaultValues(val)} />
                            :
                            this.state.filterData.filtertype == 1004 ?
                              <Thousandfour filterData={defaultValue[0].values[this.state.filterData.filtername]} onChange={(val) => this.updateDefaultValues(val)} />
                              : "" : ""
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* filter modal end */}
      </>
    )
  }
}
